import * as React from "react";
import { Anchor, Button, Heading, Paragraph } from "grommet";
import { ContentBlock } from "../../../components/content-block";

import ArtificialInteligenceImage from "../../../images/pages/technology/artificial-intelligence.png";
import BiImage from "../../../images/pages/technology/bi.png";
import BigDataImage from "../../../images/pages/technology/big-data.png";
import BlockChainImage from "../../../images/pages/technology/block-chain.png";
import IotImage from "../../../images/pages/technology/iot.png";
import DataScienceImage from "../../../images/pages/technology/data-science.png";

const TechnologyPage = () => {
  return (
    <>
      <Heading level={2} style={{ color: "#3600b2" }}>
        Technology
      </Heading>
      <Paragraph fill>
        Na atual dinâmica do mercado as organizações tem dado cada vez mais
        valor a um
        <strong style={{color:"#3600b2"}}> ativo valioso</strong> que é a <strong style={{color:"#3600b2"}}>informação</strong>, com
        o objetivo de gerar diferenciais para se destacarem no seu ambiente de
        negócio.
      </Paragraph>
      <Paragraph fill>
        Para que possamos{" "}
        <strong style={{color:"#3600b2"}}>
          transformar o dado em informação e gerar conhecimento, dependemos de
          tecnologia.
        </strong>{" "}
        As possibilidades de aplicação tecnológica para esse fim são diversas e
        cobrem todos os setores da indústria.
      </Paragraph>

      <Paragraph fill>
        Realizamos o{" "}
        <strong style={{color:"#3600b2"}}>
          fornecimento de tecnologias adequadas e das competências necessárias
          para prover a solução de forma completa
        </strong>{" "}
        aos nossos clientes, entregando{" "}
        <strong style={{color:"#3600b2"}}>
          conhecimento, experiência, boas práticas e profissionalismo
        </strong>{" "}
        em suas iniciativas.
      </Paragraph>

      <Heading level={3}>
        <strong id="BI" fill style={{ color: "#3600b2" }}>BI | Self Service BI</strong>
      </Heading>
      <ContentBlock reverse image={BiImage}>
        <Paragraph fill>
          <strong style={{color:"#3600b2"}}>Business Intelligence</strong> abrange todo processo de
          captação, estruturação, análise, compartilhamento e monitoramento de
          informações de uma empresa para oferecer suporte para um{" "}
          <strong style={{color:"#3600b2"}}>processo de tomada de decisões mais seguro</strong> e a
          criação de <strong style={{color:"#3600b2"}}>estratégias de negócios</strong>
          mais eficientes.
        </Paragraph>
      </ContentBlock>

      <Paragraph fill>
        <strong style={{color:"#3600b2"}}>Self-Service BI</strong> é o conceito onde o usuário não técnico
        possa <strong style={{color:"#3600b2"}}>criar e implantar suas próprias análises</strong> apoiado
        na estruturação de uma arquitetura corporativa e de ferramentas que
        entreguem autonomia na concepção e modelagem para a implementação de
        relatórios e dashboards.
      </Paragraph>

      <Paragraph fill>
        Possuímos <strong style={{color:"#3600b2"}}>experiência comprovada</strong> na implementação
        dessas soluções, seja no modelo tradicional ou Self-Service.
      </Paragraph>

      <Paragraph fill>
        <strong style={{color:"#3600b2"}}>Apoiamos sua empresa</strong> na democratização da informação e
        no empoderamento do negócio.
      </Paragraph>

      <ContentBlock image={BigDataImage}>
        <>
          <Heading level={3}>
            <strong id="DataLakeBigData" fill style={{ color: "#3600b2" }}>Data Lake | Big Data</strong>
          </Heading>
          <Paragraph fill>
            Estamos a disposição para apoiá-los desde a{" "}
            <strong style={{color:"#3600b2"}}>definição de infraestrutura e arquitetura</strong>, seja ela{" "}
            <strong style={{color:"#3600b2"}}>on-premisse, cloud ou hibrida,</strong> até a{" "}
            <strong style={{color:"#3600b2"}}>elaboração e execução de roadmap de projetos.</strong>
          </Paragraph>
        </>
      </ContentBlock>

      <ContentBlock reverse image={BlockChainImage}>
        <>
          <Heading level={3}>
            <strong id="Blockchain" fill style={{ color: "#3600b2" }}>Blockchain</strong>
          </Heading>
          <Paragraph fill>
            Nosso time está a disposição para ajuda-los a{" "}
            <strong style={{color:"#3600b2"}}>
              definir uma estratégia bem-sucedida voltada para adoção,
              desenvolvimento e implementação da Blockchain
            </strong>
            .
          </Paragraph>
        </>
      </ContentBlock>

      <ContentBlock image={ArtificialInteligenceImage}>
        <>
          <Heading level={3}>
            <strong id="AI" fill style={{ color: "#3600b2" }}>Artificial Intelligence</strong>
          </Heading>
          <Paragraph fill>
            Nossa equipe de consultores está pronta para{" "}
            <strong style={{color:"#3600b2"}}>
              ajudar a realizar plenamente os benefícios de negócios da IA
            </strong>{" "}
            e transformar como sua empresa opera e compete na era da{" "}
            <strong style={{color:"#3600b2"}}>transformação digital</strong>.
            <ul>
              <li>Machine learning</li>
              <li>Computer vision</li>
              <li>Natural language processing</li>
            </ul>
          </Paragraph>
        </>
      </ContentBlock>

      <ContentBlock reverse image={IotImage}>
        <>
          <Heading level={3}>
            <strong id="IoT" fill style={{ color: "#3600b2" }}>IoT</strong>
          </Heading>
          <Paragraph fill>
            Nossos consultores e parceiros possuem o conhecimento necessário
            para ajudá-los a <strong style={{color:"#3600b2"}}>construir recursos de IoT</strong> que
            podem{" "}
            <strong style={{color:"#3600b2"}}>
              criar novos fluxos de receita, aumentar a eficiência operacional e
              reduzir custos
            </strong>
            .
          </Paragraph>
        </>
      </ContentBlock>

      <ContentBlock image={DataScienceImage}>
        <>
          <Heading level={3}>
            <strong fill style={{ color: "#3600b2" }}>Data Science</strong>
          </Heading>
          <Paragraph fill>
            <strong style={{color:"#3600b2"}}>Nossos especialistas</strong> possuem conhecimento técnico e
            de negócio para
            <strong style={{color:"#3600b2"}}> estruturar os dados</strong> de maneira criativa e{" "}
            <strong style={{color:"#3600b2"}}>encontrar insights</strong>
            relevantes e úteis para seus negócios.
          </Paragraph>
        </>
      </ContentBlock>
      <span style={{ justifyContent: "flex-start", textAlign: "center" }}>
        <br />
        <a href="/contact">
          <Button
            type="submit"
            label="FALE COM NOSSO TIME"
            primary
            size="small"
            style={{ width: "250px", margin: "10px" }}
          />
        </a>{" "}
        <a
          target="_blank"
          href="https://calendly.com/datamarketplace/firstcall"
        >
          <Button
            type="submit"
            label="AGENDAR REUNIÃO"
            primary
            size="small"
            style={{ width: "250px", margin: "10px" }}
          />
        </a>
      </span>
    </>
  );
};

export default TechnologyPage;
